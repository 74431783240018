import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/sections/banner";
import bgImg from "../images/headers/subheader_blue.svg";
import bannerImage from "../images/box-headers/about_us.jpg";

const NotFoundPage = () => (
  <Layout title="404">
    <SEO title="404: Not found">
      <meta name="description" content="VostroNet is the leading internet infrastructure provider for multi-dwelling developments, delivering high-speed internet through our own fibre network."/>
    </SEO>
    <Banner className="font-white" bgImg={bgImg} data={{
      title: {
        name: "404: Not Found",
        className: "banner-title-sm",
      },
      subtitles: [
        {
          name: `VostroNet is the leading end-to-end internet infrastructure and software provider to multi-dwelling developments`,
          className: "banner-text-small",
        },
      ],
      buttonData: {},
      hideScrollButton: true,
      img: bannerImage,
    }} />
  </Layout>
)

export default NotFoundPage
